.footer_section {
    position: fixed;
    bottom: 0;
    width: calc(100% - 300px);
    padding: 0 4rem;
    right: 0;
    background: #fff;
    color: #111727;
    height: 60px;
    display: flex;
    align-items: center;
    transition: 0.5s all ease-in-out;
    justify-content: space-between;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.0862745098);
    font-weight: 500;

    .footer_menu_link {
        text-decoration: unset;

        &:hover, &.active{
            color: #1414dd;
        }
    }
}

.footer_menu {
    display: flex;
    column-gap: 3rem;

    .footer_menu_link {
        color: #111727;
        font-size: 1.4rem;
        font-weight: 400;
    }
}

.copy_right_text {
    font-size: 1.4rem;
    font-weight: 400;
}


.side_nav_open{
    .footer_section {
        width: calc(100% - 100px);
        transition: 0.5s all ease-in-out;
    }
}

@media only screen and (max-width: 1199px) {
    .footer_section {
        width: 100%;
        padding: 0 3rem;
    }

    .side_nav_open{
        .footer_section {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 991px) {
    .footer_section {
       padding: 0 2rem;
    }
}

@media only screen and (max-width: 767px) {
    .footer_section {
        padding: 1rem;
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        font-size: 1.4rem;
    }

    .footer_menu {
        column-gap: 1rem;
        width: 100%;
        justify-content: end;
    }
}
