.login_register_section {
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    .right_side_content,
    .left_side_content_filed {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 8rem;
    }
    .right_side_content {
        width: 40%;
        background: #111727;
    }
    .left_side_content_filed {
        width: 60%;
    }
}

.login_input_filed {
    width: 100%;
    max-width: 55rem;
    .logo_images {
        margin-bottom: 5rem;
        text-align: center;
        img {
            max-width: 25rem;
            height: auto;
        }
    }
}

.text_login_description {
    h1 {
        text-align: center;
        font-size: 3.6rem;
        font-weight: 600;
        color: #111727;
        line-height: 1.3;
        margin-bottom: 0.5rem;
    }
    p {
        margin: 0;
        font-size: 2rem;
        text-align: center;
        font-weight: 500;
        color: hwb(224deg 7% 85% / 70%);
    }
}

.new_user_button {
    text-align: center;
    margin-top: 3rem;
    font-weight: 400;
    font-size: 1.6rem;

    .btn_new_member {
        color: #1414dd;
        text-decoration: none;
    }
}

.login_button {
    margin-top: 7rem;
    .arrow_right_icon {
        font-size: 2rem;
        transition: 0.3s all ease-in-out;
    }
    .icon_text {
        display: flex;
        align-items: center;
        column-gap: 3rem;
    }
    .icon_plus {
        width: 3.5rem;
        height: 3.5rem;
        background: #ffffff;
        color: #111727;
        border-radius: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        transition: 0.3s all ease-in-out;
    }
    .btn_login_button {
        display: flex;
        width: 100%;
        border-radius: 0.5rem;
        padding: 0 3rem;
        height: 6rem;
        align-items: center;
        justify-content: space-between;
        font-size: 1.8rem;
        background-color: #1414dd;
        border: 1px solid #1414dd;
        color: #fff;
        transition: 0.3s all ease-in-out;
        font-weight: 500;
        text-decoration: none;
        &:hover {
            background-color: #10109b;
            border-color: #10109b;
        }
        + .btn_login_button {
            margin-top: 3rem;
            background-color: #111727;
            color: #fff;
            border-color: hsl(224deg 39% 11% / 20%);
            &:hover {
                background-color: #000000;
                color: #fff;
                border-color: #000000;

                .icon_plus {
                    background-color: #fff;
                    color: #111727;
                }

                .arrow_right_icon {
                    svg {
                        color: #fff;
                    }
                }
            }

            .icon_plus {
                background-color: #111727;
                color: #fff;
            }
        }
    }
}

.img_right_side {
    text-align: center;
    margin-bottom: 4rem;
    img {
        width: 100%;
        max-width: 35rem;
    }
}

.content_right_side {
    text-align: center;
    color: #fff;
    h2 {
        font-size: 3rem;
        margin-bottom: 1.5rem;
        font-weight: 600;
        line-height: 1.3;
    }
    p {
        margin: 0;
        line-height: 1.4;
        font-size: 1.8rem;
        color: hwb(0deg 70% 30% / 70%);
        font-weight: 400;
    }
}

.input_filed_login_register {
    margin-top: 5rem;
    .form_filed_common {
        margin-bottom: 2.5rem;
        &:last-child {
            margin-bottom: 1rem;
        }
        .user_input {
            position: relative;
            .user_icon {
                width: 45px;
                min-width: 45px;
                height: 43px;
                min-height: 43px;
                position: absolute;
                top: 1px;
                left: 1px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #d6d6d6;
                border-radius: 3px 0 0 3px;
                color: #111727;
                font-size: 18px;
            }
            .common_input_filed {
                padding: 0 2rem 0 6.5rem;
            }
        }
    }
}

.forgot_password {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
}

.btn_forgot {
    text-decoration: none;
    color: #1111aa;
    font-weight: 400;
    font-size: 1.6rem;
    &:hover {
        color: #1111aa;
    }
}

.sign_in_btn {
    margin-top: 2rem;
    .btn {
        background: #1414dd;
        border: 1px solid #1414dd;
        width: 100%;
        column-gap: 1rem;

        &:hover {
            background: #10109b;
            border-color: #10109b;
            color: #fff;
        }
        + .btn {
            margin-top: 2rem;
            background: #111727;
            border: 1px solid #111727;

            &:hover {
                background-color: #000;
                border-color: #000;
                color: #fff;
            }
        }

        svg {
            font-size: 2rem;
        }
    }
}

.login_register_section.otp_screen {
    .form_filed_common {
        div {
            justify-content: space-between;
            column-gap: 2rem;
        }
        input {
            min-width: 5rem;
            height: 5rem;
            border: 1px solid #d6d6d6;
            border-radius: 0.8rem;
            background: transparent;

            &:focus-visible{
                outline: unset;
             }

             &:focus{
                border: 1px solid #111727;
             }
        }
        &:last-child {
            margin-bottom: 2rem;
        }
    }

    .resend_otp {
        display: flex;
        justify-content: flex-end;
        column-gap: 1rem;
        span {
            width: 7rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 3rem;
            background: #000;
            color: #fff;
            border-radius: 0.5rem;
            font-size: 1.4rem;
            font-weight: 400;
        }

        .resend_otp_btn {
            border: none;
            background: transparent;
            padding: 0;
            font-size: 1.4rem;
            font-weight: 400;
            color: #1111ae;
            &.disabled{opacity: 0.5;pointer-events: none;}
        }
    }

    .input_filed_login_register {
        max-width: 42rem;
        margin: 5rem auto 0;
    }

    .sign_in_btn {
        margin-top: 3rem;

        .btn {
            width: max-content;
            margin: 0 auto;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .login_register_section {
        .right_side_content,
        .left_side_content_filed {
            padding: 2rem 3rem;
        }
    }
}

@media only screen and (max-width: 991px) {
    .content_right_side {
        h2 {
            font-size: 2rem;
        }
        p {
            font-size: 1.4rem;
        }
    }
    .text_login_description {
        h1 {
            font-size: 3rem;
        }
        p {
            font-size: 1.6rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .login_register_section {
        flex-direction: column-reverse;
        .left_side_content_filed,
        .right_side_content {
            width: 100%;
            min-height: auto;
            padding: 6rem 3rem;
        }

        .right_side_content {
            display: none;
        }
    }
    .img_right_side {
        img {
            width: 100%;
            max-width: 28rem;
        }
    }
    .text_login_description {
        h1 {
            font-size: 2.5rem;
        }
    }
    .login_button {
        .btn_login_button {
            padding: 0 2rem;
            font-size: 1.6rem;
            height: 6rem;
        }
        .icon_text {
            column-gap: 2rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .login_register_section.otp_screen {
        .form_filed_common {
            div {
                column-gap: 1rem;
            }

             input {
                min-width: 4rem;
                height: 4rem;
                border: 1px solid #d6d6d6;
                border-radius: 0.8rem;
                background: transparent;
            }
        }
    }
}