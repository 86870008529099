.about_us_page_content {
    margin-top: 10rem;
    position: relative;

    h2 {
        font-size: 1.4rem;
        color: #4669ce;
        font-weight: 400;
        margin-bottom: 0.5rem;
    }

    h3 {
        margin-bottom: 2rem;
        font-size: 3rem;
        color: #111727;
        font-weight: 400;
    }

    p {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        font-weight: 400;
        color: hwb(224deg 7% 85%/70%);
        text-align: justify;
    }

    h5 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
        position: relative;
        color: #111727;
        font-weight: 400;
    }
}

.img_right_side_about {
    margin: 0 8rem;

    img {
        width: 100%;
    }
}

.second_section_about {
    padding-top: 10rem;
}

.center_text_about {
    text-align: center;
    max-width: 110rem;
    margin: auto;
    padding-bottom: 10rem;

    p {
        text-align: center;
    }
}

.goal_undo {
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    border: 4px solid #4669ce;
    border-radius: 50%;
}

.left_side_list {
    .list_first_about {
        display: flex;
        column-gap: 1.5rem;
        position: relative;
        margin-bottom: 2rem;

        &::after {
            content: "";
            height: 100%;
            top: 2rem;
            left: 0.9rem;
            position: absolute;
            border: 1px dashed hwb(224deg 7% 85% / 20%);
        }

        &:last-child {
            margin-bottom: 0;

            &::after {
                display: none;
            }
        }
    }
}

.cms_page_common_section {
    margin-top: 5rem;

    h1 {
        font-size: 2.5rem;
        font-weight: 400;
        margin-bottom: 1rem;
        color: #111727;
    }
}



@media only screen and (max-width: 1199px){
    .img_right_side_about {
        margin: 0 2rem;
    }
}

@media only screen and (max-width: 1199px){
    .about_us_page_content {
        h3 {
            font-size: 3rem;
        }
        p {
            font-size: 1.6rem;
        }

        h5 {
            font-size: 1.8rem;
        }
    }
}

@media only screen and (max-width: 767px){
    .img_right_side_about {
        margin: 0 6rem;
    }

    .left_side_list {
        margin-top: 5rem;
    }

    h2 {
        margin-top: 3rem;
    }

    .second_section_about {
        padding-top: 5rem;
    }

    .center_text_about {
        padding-bottom: 5rem;
    }

    .about_us_page_content {
        margin-top: 5rem;
    }

    .cms_page_common_section {
        margin-top: 3rem;

        h1 {
            font-size: 2rem;
        }
        p{
            font-size: 1.4rem;
        }
    }
}

@media only screen and (max-width: 575px){
    .img_right_side_about {
        margin: 0 2rem;
    }

    .about_us_page_content {
        h2 {
            font-size: 1.6rem;
        }

        h3 {
            font-size: 2.5rem;
        }

        h5 {
            font-size: 1.6rem;
            margin-bottom: 0.5rem;
        }
    }

    .second_section_about {
        padding-top: 3rem;
    }
}
