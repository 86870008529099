.page-not-found {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #e9f0fe;

    h1 {
        font-size: 16rem;
        font-weight: 500;
    }
    h2 {
        font-size: 6.2rem;
        font-weight: 400;
    }
    h3 {
        font-size: 2.8rem;
        font-weight: 300;
        color: #232323;
    }
    .btn_blue {
        margin: 5rem auto 0;
        max-width: 20rem;
    }

    &.ppc_redirect {
        h4 {
            font-size: 7.5rem;
            margin-bottom: 3rem;
        }
        p {
            font-size: 3rem;
            font-weight: 400;
        }
    }
}
