.new_Campaigns_modal {
    .modal-dialog {
        max-width: 60rem;
        margin: 0 auto;
    }
    .modal-header,
    .modal-body {
        padding: 2rem 4rem;
        h4 {
            font-size: 2.2rem;
            font-weight: 500;
            color: #111727;
            margin-bottom: 0;
        }
        .btn-close {
            opacity: 1;
            font-size: 18px;
            padding: 0;
            &:focus {
                box-shadow: unset;
            }
        }
    }
}

.new_Campaigns_create_section {
    .new_Campaigns_input {
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 0;
        }
        .accordion-item {
            border: none;
            width: 100%;
            padding: 0;
        }
        .accordion-button {
            padding: 0;
            font-size: 1.6rem;
            font-weight: 500;
            color: #111727;
            &:focus {
                box-shadow: unset;
            }
            &::after {
                margin-left: 1rem;
                background-image: url('data:image/svg+xml,<svg viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="%23000000" /></svg>');
                background-repeat: no-repeat;
                width: 2rem;
                background-size: 2rem;
                height: 2rem;
            }
        }
        .accordion-button:not(.collapsed) {
            box-shadow: unset;
            background-color: transparent;
            color: #111727;
            padding: 0;
        }
        .accordion-body {
            padding: 0;
            margin-top: 1rem;
            .check_box_first {
                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.tow_btn_row {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;
    .btn_blue {
        width: 100%;
        min-width: auto;
    }
}

.delete_Campaigns_modal {
    .modal-header {
        padding: 0;
        border: none;
    }
    .btn-close {
        position: absolute;
        right: 2rem;
        top: 2rem;
        z-index: 9;
    }
    .delete_content_camp {
        padding-top: 4rem;
        padding-bottom: 4rem;
        text-align: center;

        .amount_add_section{
            label{
                text-align: start;
            }
        }
    }
    h1 {
        font-size: 2.8rem;
        margin: 0;
        font-weight: 500;
        line-height: 1.3;
    }

    .modal-dialog {
        max-width: 50rem;
        margin: 0 auto;
    }

    &.change_password_modal {
        .modal-dialog {
            max-width: 90rem;
        }
    }
}

.change_password_modal {
    .tow_btn_row {
        .btn_blue {
            width: auto;
            min-width: 16rem;
            margin: 0 auto;
        }
    }
    .change_password_filed {
        margin: 2rem 0 6rem;
    }
}

.user_input {
    .input-group-btn {
        position: relative;

        .eye_btn_password {
            position: absolute;
            right: 1rem;
            padding: 0;
            border: none;
            background: transparent;
            font-size: 1.8rem;
            color: #111727;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.complete_profile_modal {
    .modal-dialog {
        max-width: 100rem;
        margin: 0 auto;
    }

    .modal-body {
        padding: 4rem;
    }

    .complete_profile_input {
        padding: 4rem 0;
    }

    h1 {
        text-align: center;
        font-size: 3.5rem;
        font-weight: 500;
    }

    .tow_btn_row {
        .btn_blue {
            width: 16rem;
            margin: 0 auto;
        }
    }
}

.middle_heading {
    margin: 0;
    font-size: 2rem;
    font-weight: 500;
}

.notifications_modal {
    .modal-dialog {
        margin: 0 0 0 auto;
        max-width: 45rem;
    }

    h4 {
        display: flex;
        align-items: baseline;
        gap: 1rem;

        span {
            font-size: 14px;
            color: #0a0a6e;
            font-weight: 500;
        }
    }

    .modal-content {
        border-radius: 1rem 0 0 1rem;
        height: 100vh;
        overflow: hidden;
    }

    .modal-body {
        margin: 2.5rem 1.5rem 2.5rem 3rem;
        overflow: hidden;
        padding: 0;
    }

    .notification-content {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 1.5rem;
    }

    .campaigns_list_not {
        padding-bottom: 1.5rem;
        border-bottom: 1px solid #d6d6d6;
        margin-bottom: 1.5rem;

        .heading_campaigns_req {
            text-decoration: none;
            display: inline-block;
            color: #0a0a6e;
            font-size: 1.6rem;
            font-weight: 400;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.heading_time_section {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    .time_notification {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
    }
}

.button_yes_no {
    display: flex;
    gap: 2rem;

    .yes_no_btn {
        padding: 0;
        border: none;
        background: transparent;
        font-size: 1.6rem;

        &.yes_btn {
            color: #1414dd;
        }

        &.no_btn {
            color: #111727;
        }
    }
}

.modal-dialog-centered {
    min-height: 100%;
}

.addCredit-point-block {
    display: flex;
    align-items: end;

    .common_input_filed {
        border-radius: 0.4rem 0 0 0.4rem;
    }

    .btn_blue {
        height: 4.5rem;
        border-radius: 0 0.4rem 0.4rem 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: 991px) {
    .complete_profile_modal {
        .modal-dialog {
            margin: 0 2rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .complete_profile_modal {
        .complete_profile_input {
            padding: 4rem 0;
        }
        h1 {
            font-size: 3rem;
        }

        .modal-body {
            padding: 2rem;
        }
    }

    .delete_Campaigns_modal {
        h1 {
            font-size: 2.4rem;
            margin: 0;
            font-weight: 500;
            line-height: 1.3;
        }
    }

    .new_Campaigns_modal {
        .modal-body,
        .modal-header {
            padding: 2rem;
        }
    }

    .new_Campaigns_modal.change_password_modal,
    .new_Campaigns_modal.delete_Campaigns_modal {
        .modal-header {
            padding: 0;
        }
    }

    .new_Campaigns_modal.complete_profile_modal {
        .modal-header {
            padding: 0;
        }
    }

    .delete_Campaigns_modal {
        .delete_content_camp {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }

    .delete_Campaigns_modal.change_password_modal .modal-dialog {
        margin: 0 2rem;
    }

    .change_password_modal {
        .change_password_filed {
            margin: 2rem 0;
        }
    }
}

@media only screen and (max-width: 575px) {
    .delete_Campaigns_modal.change_password_modal .modal-dialog {
        margin: 0 1.5rem;
    }

    .complete_profile_modal,
    .new_Campaigns_modal {
        .modal-dialog {
            margin: 0 1.5rem;
        }
    }
}


.campaign_view_details {
    h5 {
        font-size: 16px;
        font-weight: 300;
    }
}

.campaign_img {
    height: 20rem;
    width: 100%;
    overflow: hidden;
    margin-bottom: 2rem;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.tow_btn_row .btn_blue.proceed_btn {
    background: #161616;
    border-color: #161616;
    color: #fff;
}

.campaign_view_details {
    h1{
        font-size: 22px;
        margin-bottom: 15px;
    }
}

.campaign_modal_view{
    .modal-header {
        justify-content: center;
        font-size: 30px;
    }
    
}

.multi_select_email{
    
}