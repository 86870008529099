.top_heading_button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.common_tab_section {
    margin-top: 5rem;
    .nav.nav-tabs {
        border: none;
        margin-bottom: 1.5rem;
        padding: 2rem;
        background: #fff;
        border-radius: 0.5rem;
        box-shadow: 5px 5px 20px rgb(0 0 0 / 16%);
        flex-wrap: nowrap;
        overflow-y: hidden;
        overflow-x: auto;

        .nav-item {
            margin-right: 3rem;
            white-space: nowrap;

            &:last-child {
                .nav-link {
                    border-right: unset;
                    padding-right: 0;
                }
            }
        }
        .nav-link {
            padding: 0 3rem;
            background: transparent;
            color: #111727;
            font-size: 1.6rem;
            font-weight: 400;
            border: none;
            border-right: 1px solid #d6d6d6;
            border-radius: 0;
            &:first-child {
                padding-left: 0;
            }
            &.active {
                background: transparent;
                color: #1414dd;
                border-color: #1414dd;
            }
            &:focus-visible {
                outline: unset;
                box-shadow: unset;
            }
        }
    }
}

.content_campaigns_user {
    background: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    border: 1px solid #e0e0e0;
    .campaigns_name {
        margin: 0;
        font-size: 1.6rem;
        color: #111727;
        font-weight: 400;
        text-decoration: none;
    }
}

.drop-box {
    .btn_three_dots {
        min-width: auto;
        width: auto;
        padding: 0;
        font-size: 30px;
        background: transparent;
        color: #111727;
        border: none;
        height: auto;
        &:focus-visible,
        &:active {
            box-shadow: unset;
            background-color: transparent;
            border: 0;
        }
        &::before {
            content: unset;
        }
    }
}

.price_product {
    display: flex;
    column-gap: 2rem;
    align-items: center;
    margin-top: 1rem;
    .price_products {
        display: flex;
        gap: 0.5rem;
        color: #535151;
        font-size: 1.4rem;
        font-weight: 400;
        svg {
            width: 1.6rem;
            min-width: 1.6rem;
            height: 1.6rem;
            margin-top: 0.2rem;
        }
    }
}

.campaigns_list,
.campaigns_list_card {
    .btn_blue {
        min-width: 10rem;
        height: 3.5rem;
        font-size: 1.4rem;
        color: #fff;
        background-color: #3f51b5;
        border-color: #3f51b5;
        padding: 0 1rem;

        &.manage {
            background-color: #535151;
            border-color: #535151;
        }

        &.edit_delete {
            min-width: auto;
            padding: 0 5px;
            background: #3f51b5;
            border-color: #3f51b5;

            &.delete {
                background: #ed5e68;
                border-color: #ed5e68;
            }
        }

        &.joined-btn,
        &:disabled {
            background-color: #009688;
            border: #009688;
        }

        &.reject {
            background-color: #009688;
            border: #009688;
        }

        &.active {
            background-color: #16b443;
            border-color: #16b443;
        }
    }
}

.card_box_Campaigns {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    position: relative;
    &::after {
        content: "";
        height: 1px;
        background-color: #f1f1f1;
        width: calc(100% + 4rem);
        position: absolute;
        bottom: 0;
        left: -2rem;
    }
    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        &::after {
            content: unset;
        }
    }

    .campaigns_list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;

        p {
            margin-bottom: 0;
        }
    }

    .my_campaigns_right_button {
        display: flex;
        align-items: center;
        column-gap: 1rem;
    }
}

.check_q_a_section {
    margin-top: 5rem;
    .accordion-item {
        margin-bottom: 2rem;
        border: none;
        border-radius: 0.8rem;

        &:first-of-type,
        &:last-of-type {
            .accordion-button.collapsed {
                border-radius: 0.8rem;
            }
            .accordion-button {
                border-radius: 0.8rem 0.8rem 0 0;
            }
        }

        h2 {
            margin-top: 0rem;
        }
    }

    .accordion-button {
        background: #dbdde9;
        padding: 2.5rem 5rem 2.5rem 2rem;
        border-radius: 0.8rem 0.8rem 0 0;
        border: 1px solid #efefef;
        font-size: 1.8rem;
        font-weight: 400;
        box-shadow: unset;
        &:focus {
            box-shadow: unset;
        }
        &:hover {
            z-index: 0;
        }
        &::after {
            background-image: none;
            content: "";
            position: absolute;
            top: 3.5rem;
            right: 2rem;
            width: 16px;
            height: 3px;
            background-color: #111727;
        }
        &.collapsed {
            border: 1px solid #efefef;
            border-radius: 0.8rem;
            color: #111727;

            &::before {
                content: "+";
                position: absolute;
                right: 2rem;
                top: 1.8rem;
                font-size: 30px;
                color: #111727;
            }

            &::after {
                display: none;
            }
        }
    }

    .accordion-body {
        border: 1px solid #eaeaea;
        border-top: 0;
        border-radius: 0 0 0.8rem 0.8rem;
        padding: 2rem;

        h4 {
            font-size: 16px;
            font-weight: 400;
            color: hsl(224deg 39% 11% / 70%);
        }
    }
}

.support_contact_section {
    margin-top: 5rem;

    .support_contact_form {
        background: #fff;
        padding: 4rem;
        border-radius: 0.6rem;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.16);
        height: 100%;
        display: flex;
        flex-direction: column;

        &.right_side_img {
            padding-bottom: 0;
        }

        .support_contact_input {
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
                margin-top: auto;
            }
        }

        .user_name_id {
            border: 1px solid #d6d6d6;
            margin-bottom: 3rem;
            padding: 1.5rem;
            border-radius: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }

            h3 {
                font-size: 1.8rem;
                font-weight: 400;
                margin-bottom: 5px;
                color: #1414dd;
            }

            p {
                margin-bottom: 0;
                font-weight: 400;
                font-size: 1.4rem;
            }
        }
    }
}

.request_campaigns_list {
    .content_campaigns_user {
        padding: 0rem;

        table {
            margin-bottom: 0;
            overflow: hidden;
            border-radius: 0.5rem;

            th {
                padding: 2rem;
                background: hsl(228, 100%, 99%);
                font-size: 1.6rem;
                font-weight: 400;
                vertical-align: middle;
                white-space: nowrap;
                text-transform: capitalize;
            }

            td {
                padding: 1.5rem 2rem;
                border-bottom: 1px solid #e0e0e0;
                font-size: 1.4rem;
                font-weight: 400;
                vertical-align: middle;
                white-space: nowrap;
                max-width: 300px;
                text-overflow: ellipsis;
                overflow: hidden;

                &.price_text_table {
                    color: #009688;
                    font-weight: 500;
                }
            }

            // tr {
            //     &:last-child {
            //         td {
            //             border-bottom: 0;
            //         }
            //     }
            // }
        }
    }
}

.profile_img_table {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}

.table_btn {
    min-width: 6rem;
    height: 2.6rem;
    min-height: 2.6rem;
    border-radius: 0.4rem;
    border: 1px solid #000;
    padding: 0 0.5rem;
    background: transparent;
    font-size: 13px;
    font-weight: 400;
    color: #fff;

    + .table_btn {
        margin-left: 1rem;
    }

    &.reject {
        border-color: #535151;
        background-color: #535151;
    }

    &.approve {
        border-color: #3f51b5;
        background-color: #3f51b5;
    }

    &.active {
        border-color: #009688;
        background: #009688;
    }

    &.pending {
        border-color: #fed756;
        background-color: #fed756;
        color: #161616;
    }

    &.resolved {
        border-color: #28a745;
        background: #28a745;
        color: #fff;
    }

    &.manager {
        color: #fff;
        background-color: #3f51b5;
        border-color: #3f51b5;
    }

    &.view_page_btn {
        min-width: 14rem;
        height: 5rem;
        font-size: 1.6rem;
        font-weight: 400;
    }
}

.support_img {
    margin-top: 5rem;
    text-align: center;
}

.profile_page {
    margin-top: 5rem;
    padding: 3rem;
    border-radius: 1rem;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;

    h3 {
        font-size: 2rem;

        &.main_heading_top {
            &::after {
                height: 3px;
            }
        }
    }
}

.profile-edit {
    // background-image: url(../../public/images/dummy-image.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    height: 15rem;
    margin: 0 auto 5rem;
    overflow: hidden;
    position: relative;
    width: 15rem;

    .edit-label {
        align-items: center;
        background-color: rgba(0, 0, 0, 0.66);
        bottom: 0;
        color: #fff;
        cursor: pointer;
        display: inline-flex;
        font-size: 14px;
        font-weight: 500;
        height: 4.5rem;
        justify-content: center;
        left: 0;
        position: absolute;
        width: 100%;
    }
}

.admin_list_table {
    margin-top: 5rem;
}

.filter_number_search_input {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 2rem;

    .filter_search_common {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .filter_select_number {
        max-width: 20rem;
        min-width: 12rem;
    }

    .common_label {
        width: auto;
        margin: 0;
    }

    select.common_input_filed {
        background-position: center right 1rem;
        padding-right: 4rem;
        padding-left: 1rem;
    }
}

.pagination_entries_section {
    padding: 2rem;
    // border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;

    .entries_text {
        font-size: 1.4rem;
        color: #111727;
        font-weight: 400;
    }
}

.custom-card {
    padding: 2rem;
    background: #fff;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 10px;
    border-bottom: 5px solid #1414ddb3;

    .card-body2 {
        display: flex;
        align-items: center;
        column-gap: 2rem;
    }

    &.tow {
        border-color: #009688d1;
    }

    &.three {
        border-color: #111727d6;
    }
}

.icon_card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    background: rgb(242 238 252);
    border-radius: 10px;
    color: #000;

    .icon_box {
        background: #1414ddb3;
        font-size: 2.4rem;
        border-radius: 4px;
        width: 3.5rem;
        height: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;

        &.tow {
            background: #009688d1;
        }

        &.three {
            background: #111727d6;
        }
    }
}

.text_card {
    .title_name {
        font-size: 1.8rem;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .text-count {
        font-size: 3rem;
        line-height: 0.2;
        margin-bottom: 5px;
    }

    .percentage_text {
        color: #5655e6;
        font-weight: 500;

        &.tow {
            color: #2ca69c;
        }

        &.three {
            color: #111727;
        }
    }
}

.cart_manager_marketer {
    padding: 2rem;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 10px;

    a.canvasjs-chart-credit {
        display: none;
    }
}

.new_Campaigns_view {
    background: #fff;
    padding: 3rem;
    border-radius: 1rem;

    .view_campaigns_card {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    p {
        margin: 0;
        display: flex;
        align-items: center;
        padding: 0;
        min-height: 0;
        height: auto;
        word-break: break-word;
        flex-wrap: wrap;
        row-gap: 5px;
        column-gap: 5px;
        margin-top: 5px;

        span {
            display: block;
            background: #111727;
            color: #fff;
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            font-size: 1.2rem;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .view_img {
        height: 40rem;
        text-align: center;
        overflow: hidden;
        margin-bottom: 30px;
        border-radius: 5px 5px 0 0;
        position: relative;
        background: #efefef;
        img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }

        .join_view_campaigns {
            position: absolute;
            bottom: 20px;
            right: 20px;

            .btn_blue {
                border: 1px solid #009688;
                background: #009688;
            }
        }
    }

    .campaings_content_view {
        padding: 0 3rem 3rem;
    }

    .campaigns_name {
        font-size: 30px;
        margin-bottom: 5px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }
    

    .price_text {
        color: #009688;
        font-size: 24px;
    }

    .heading_text {
        font-size: 18px;
        font-weight: 500;
        margin: 20px 0 0;
    }
}

.document_img_upload {
    position: relative;

    .img-container {
        width: 8rem;
        height: 8rem;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
}

.contact_ticket_page {
    margin-top: 5rem;

    .content_box_ticket {
        text-decoration: none;
        background: #fff;
        padding: 1.5rem;
        display: block;
        border-radius: 0.5rem;

        h3 {
            font-size: 1.8rem;
            color: #111727;
            font-weight: 500;
            margin: 0;
        }

        p {
            margin: 0;
            margin-top: 7px;
            color: #828282;
            font-size: 1.5rem;
        }
        .date_comment_box {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            font-size: 16px;
            color: #111727;
            font-weight: 400;

            &.ticket_code_status {
                margin-top: 15px;
                position: relative;
                padding-top: 1.5rem;

                &::after {
                    position: absolute;
                    content: "";
                    height: 1px;
                    width: calc(100% + 3rem);
                    top: 0;
                    left: -1.5rem;
                    background-color: #00000021;
                }

                .ticket_status_btn {
                    background: transparent;
                    border: none;
                    padding: 0;
                    font-size: 16px;
                    font-weight: 500;

                    &.active {
                        color: #439552;
                    }

                    &.closed {
                        color: #c1302a;
                    }
                }

                .ticket_code {
                    span {
                        text-transform: uppercase;
                        color: #1414dd;
                    }
                }
            }
        }

        .comment_number {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }

    .description_text_ticket {
        margin: 0;
        margin-top: 15px;
        font-weight: 400;
    }
}

.user_card_admin_message {
    margin-top: 3rem;
    height: 53rem;
    min-height: 53rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.admin_ticket_card {
        height: 58rem;
        min-height: 58rem;
    }
}

.view_contact_support {
    width: 150px;
    select {
        max-width: 160px;
        height: 45px;
        font-size: 16px;
        background: #111727;
        color: #fff;
        border-color: #111727;
        cursor: pointer;
        background-image: url(../../public/images/dropdown_white.svg);
        background-repeat: no-repeat;
        background-size: 18px;
        background-position: center right 10px;

        &:focus {
            color: #fff;
            background-color: #111727;
            border-color: #111727;
            outline: 0;
            box-shadow: unset;
        }
    }
}

.massage_admin_user {
    padding: 2rem;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 2rem;
    background: #fff;
    border-radius: 10px;
}

.card_admin_box {
    margin-bottom: 3rem;
    position: relative;

    &::after {
        content: "";
        height: calc(100% - 2rem);
        width: 1px;
        background-color: #eeeeee;
        position: absolute;
        left: 2.4rem;
        top: 5rem;
    }

    &:last-child {
        margin-bottom: 0;

        &::after {
            display: none;
        }
    }
}

.img_content_box {
    display: flex;
    column-gap: 2rem;

    .content_user_box {
        width: 100%;
    }

    .top_name_date {
        justify-content: space-between;
        display: flex;
        column-gap: 2rem;
        margin-bottom: 5px;
    }

    .user_profile {
        width: 5rem;
        height: 5rem;
        min-width: 5rem;
        border-radius: 50%;
        overflow: hidden;

        .profile-initial {
            width: 100%;
            height: 100%;
            background-color: #111727;
            color: #fff;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    p {
        color: #828282;
        font-size: 15px;
        font-weight: 400;
        margin: 0;
    }
}

.uploadFile-block {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 9rem;
    font-size: 30px;
    color: #111727;

    svg {
        cursor: pointer;
    }
}

.chat-document-file {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    background: #00000005;
    border: 1px solid #d6d6d6;
    padding: 5px 10px;
    border-radius: 5px;
    width: 50%;

    .download_icon {
        font-size: 22px;
        color: #1414dd;
    }

    .icon {
        width: 5rem;
        min-width: 5rem;
        height: 6rem;
        border: 1px solid #d1cece;
        object-fit: contain;
        object-position: center;
        padding: 6px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.icon_name_img {
    display: flex;
    gap: 10px;
}

.typing_message_box {
    border-top: 1px solid #eeeeee;

    .typing_input {
        width: 100%;
        height: 6rem;
        padding: 0 9rem 0 2rem;
        font-size: 16px;
        font-weight: 500;
        font-family: "Poppins";
        border-radius: 1rem;
        border: 1px solid #cccccc;
        color: #959595;

        &:focus {
            outline: 0 !important;
        }
    }
}

.typing_message_box_main {
    margin-top: auto;
}

.typing_main_box {
    position: relative;

    .sent_message_icon {
        background: #111727;
        color: #fff;
        height: 5rem;
        width: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
        border: 1px solid #111727;
        border-radius: 1rem;
        position: absolute;
        top: 3rem;
        right: 2rem;
        transform: translateY(-50%);
        min-width: auto;
    }
}

.closed_card {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fcfcfc;
    padding: 2rem;
    font-size: 16px;
    font-weight: 500;
    color: #828282;
    padding: 3rem;
    border-top: 1px solid #eeeeee;
}

.card_campaigns_main {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    height: 100%;

    .price_campaigns {
        flex: 1 1 auto;
        padding-left: 20px;
    }

    .price_heading_box {
        display: flex;

        h1 {
            margin: 0;
            color: #009688;
            font-weight: 500;
            font-size: 20px;
            white-space: nowrap;
            display: flex;
            align-items: flex-start;
            justify-content: end;

            svg {
                height: 24px;
            }

            .price_number_text {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }

        .heading_campaings {
            font-size: 22px;
            color: #111727;
            text-decoration: none;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;

            &:hover {
                color: #1414dd;
            }

            .btn_blue {
                border: 1px solid rgba(17, 23, 39, 0);
                background: rgba(255, 255, 255, 0);
                height: 3rem;
                padding: 0;
                color: #000;
                gap: 5px;
                padding: 0;
            }
        }
    }

    .details-box-first {
        h2 {
            margin-bottom: 5px;
            font-size: 16px;
            font-weight: 500;
            margin-top: 0;
        }

        p {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
        }

        .campaings_detail {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .text-ellipsis {
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            display: block;
        }

        .tags_line {
            margin: 6px 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                background: #dbdde991;
                color: #000;
                padding: 4px 8px;
                border-radius: 5px;
                margin: 3px;
                display: inline-block;
            }
        }
    }

    .details-box-first2 {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        .btn_blue {
            width: 100%;
            height: 3.5rem;
            padding: 0;
            background: transparent;
            border: none;
            justify-content: flex-start;
            min-width: unset;
        }

        h1 {
            font-weight: 400;
            font-size: 16px;
            justify-content: start;

            svg {
                height: 19px;
            }
        }
    }
}

.share_next_page {
    width: 3.5rem;
    min-width: 3.5rem;
    padding: 5px;
    height: 3.5rem;
    border-radius: 50%;
    background: rgb(20 20 221 / 11%);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: #111727;
        width: 1.8rem;
        height: 1.8rem;

        path {
            stroke: #111727;
            fill: #111727;
        }
    }
}

.join_product_type_row {
    display: flex;
    justify-content: end;
    margin-top: 5px;
    gap: 5px;

    .btn_blue {
        white-space: nowrap;
    }
}




@media only screen and (max-width: 1400px) {
    .support_img {
        img {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 991px) {
    .support_contact_section {
        .support_contact_form {
            padding: 2rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .card_box_Campaigns {
        .campaigns_list {
            .btn_blue {
                min-width: 8rem;
                height: 3.5rem;
            }
        }
    }

    .check_q_a_section {
        .accordion-button {
            padding: 1.5rem 4rem 1.5rem 1.5rem;
            font-size: 1.6rem;
        }

        .accordion-body {
            padding: 1.5rem;

            h4 {
                font-size: 14px;
                font-weight: 400;
                color: hsla(224, 39%, 11%, 0.7);
            }
        }
    }

    .profile_page {
        margin-top: 3rem;
        padding: 3rem 2rem;
    }

    .profile-edit {
        height: 12rem;
        margin: 0 auto 3rem;
        width: 12rem;
    }

    .common_tab_section,
    .support_contact_section,
    .admin_list_table {
        margin-top: 3rem;
    }

    .profile-edit .edit-label {
        height: 4rem;
        font-size: 12px;
    }

    .pagination_entries_section {
        padding: 1rem;
    }

    .profile_img_table {
        width: 4rem;
        height: 4rem;
    }

    .check_q_a_section {
        margin-top: 3rem;
    }

    .campaigns_list .btn_blue,
    .campaigns_list_card .btn_blue {
        min-width: 5rem;
    }
}

@media only screen and (max-width: 575px) {
    .card_box_Campaigns {
        .campaigns_list {
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
        }
        .my_campaigns_right_button {
            width: 100%;
            justify-content: flex-end;
        }
    }

    .card_campaigns_main .price_heading_box {
        flex-direction: column;
    }

    .card_campaigns_main .price_heading_box h1, .join_product_type_row{
        justify-content: start;
    }

    .card_campaigns_main .price_campaigns {
        padding-left: 0;
        margin: 10px 0;
    }

    .top_heading_button {
        flex-direction: column-reverse;
        align-items: flex-start;

        .btn_blue {
            margin: 0 0 0 auto;
        }
    }

    .filter_number_search_input {
        select.common_input_filed {
            background-position: center right 0.5rem;
            padding-right: 2rem;
            padding-left: 0.5rem;
        }
    }
}

.view_page_management {
    margin-top: 5rem;
}

.view_page_content_section {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 3rem;
    margin-top: 5rem;
}

.certificate_img_pdf {
    width: 8rem;
    height: 4rem;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.heading_description {
    background: #e9e9e9;
    padding: 1.5rem;
    border-radius: 0.5rem;

    &:last-child {
        margin-bottom: 0;
    }

    h1 {
        margin-bottom: 1rem;
        font-size: 1.8rem;
        color: #111727;
    }

    p {
        margin: 0;
        font-size: 1.4rem;
        word-break: break-word;
    }

    img {
        width: 100%;
        max-width: 100px;
    }

    &.marketing_img_assets {
        margin: 3rem 0;
    }
}

.profile_view_page {
    background: #fff;
    padding: 3rem;
    border-radius: 1rem;

    .profile_img_table {
        width: 10rem;
        height: 10rem;

        .common_design_img {
            object-fit: cover;
        }
    }
}

.tag-block {
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 8px;

    span {
        display: block;
        background: #111727;
        color: #fff;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        font-size: 1.2rem;
    }
}



.react-datepicker__input-container {
    width: 25rem !important;
    height: 4.5rem;
    border-radius: 0.4rem;
    border: 1px solid #d6d6d6;
    padding: 0 1rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: #111727;
    display: flex;
    align-items: center;
    background: #fff;

    .form-control {
        border: none;
        padding: 0;
        font-size: 14px;
        height: 100%;
    }
}


@media only screen and (max-width: 1450px) {
    .campaigns_list, .campaigns_list_card {
        .btn_blue {
            min-width: auto;
            padding: 0 6px;
        }
    }
}