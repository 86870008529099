.choose_method_section {
    margin-top: 5rem;
    padding: 3rem;
    background-color: #fff;
    border-radius: 1rem;
}

.multi_payment_card {
    display: flex;
    gap: 3rem;
    max-width: 75%;
}

.card_payment {
    width: 100%;

    label {
        min-height: 6rem;
        display: flex;
        align-items: center;
        border: 1px solid #d6d6d6;
        padding: 1.5rem 2rem;
        border-radius: 0.5rem;
        width: 100%;
        font-size: 1.8rem;
        font-weight: 400;
        cursor: pointer;
    }

    .delete_card_icon {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        background-color: #535151;
        border: 1px solid #535151;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: 0;
    }

    p {
        font-size: 1.6rem;
        font-weight: 400;
        margin: 0;
    }

    .card_user_name {
        display: flex;
        gap: 1rem;
    }

    h3 {
        font-size: 1.6rem;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .card_logo {
        max-width: 4rem;
        width: 4rem;
        height: 3rem;
        border: 1px solid #d6d6d6;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }

    input {
        &:checked {
            + label {
                border-color: #1414dd;
                color: #1414dd;
                box-shadow: 0 0 20px hsl(240deg 83% 47% / 10%);

                .delete_card_icon {
                    background-color: #1414dd;
                    border: 1px solid #1414dd;
                }
            }
        }
    }
}

.credit_card_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
        margin-top: 0;
    }
}

.credit_card_section {
    margin-top: 5rem;
}

.multi_saved_card {
    margin-top: 3rem;
    display: grid;
    gap: 3rem;
    grid-template-columns: auto auto auto;
}

.user_card_section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
}

.card_wallet {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wallet_page_section {
    h2 {
        font-size: 2.2rem;
        font-weight: 400;
        margin: 0;
        padding-bottom: 0.6rem;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 4rem;
            height: 2px;
            background-color: #111727;
            left: 0;
            bottom: 0;
        }
    }
}

.add_amount_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    .btn_blue + .btn_blue {
        background: #1414dd;
        color: #fff;
        border-color: #1414dd;

        &:hover {
            background: #10109b;
            border-color: #10109b;
            color: #fff;
        }
    }
}

.icon_balance_heading {
    display: flex;
    align-items: center;
    gap: 2rem;

    svg {
        font-size: 6rem;
        color: #1414dd;
    }

    h3 {
        margin-bottom: 0.5rem;
        font-size: 1.6rem;
    }

    .balance {
        margin: 0;
        font-size: 3rem;
    }
}

.three_card_withdrawal {
    margin-top: 3rem;

    .three_card_section {
        display: flex;
        gap: 2rem;
    }

    .card_three_box {
        background: #f9f1fc;
        padding: 1.5rem;
        border-radius: 0.5rem;
        width: 100%;

        &.deposit {
            background: #e3faea;
        }
        &.withdrawal {
            background: #f4f2ff;
        }
        &.campaign_deduction {
            background: #fff2f2;
        }
    }
}

.icon_heading_price {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    p,
    h3 {
        margin: 0;
    }

    h3 {
        font-size: 1.8rem;
    }
}

.icon_box {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid;

    svg {
        font-size: 2.4rem;
    }

    &.earn {
        border-color: #a633ce;

        svg {
            color: #a633ce;
        }
    }

    &.withdrawal {
        border-color: #370ddc;

        svg {
            color: #370ddc;
        }
    }

    &.deposit {
        border-color: #16b443;

        svg {
            color: #16b443;
        }
    }

    &.campaign_deduction {
        border-color: #fb7272;

        svg {
            color: #fb7272;
        }
    }
}

.profile_img_history {
    width: 5rem;
    height: 5rem;
    overflow: hidden;
    border-radius: 1rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.history_user_name_profile {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.history_table {
    margin-top: 5rem;

    table {
        margin-bottom: 0;

        tr {
            border-bottom: 1px solid #dfdfdf;
        }

        td {
            vertical-align: middle;
            font-size: 1.6rem;
            padding: 1rem;
            font-weight: 400;
            white-space: nowrap;
            border: none;
            padding-bottom: 2rem;

            &.debit {
                color: red;
            }

            &.credit {
                color: green;
            }
        }
    }
}

.payment_history_heading {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

@media only screen and (max-width: 1399px) {
    .multi_saved_card {
        grid-template-columns: auto auto;
    }
}

@media only screen and (max-width: 991px) {
    .multi_payment_card {
        max-width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .multi_saved_card {
        grid-template-columns: auto;
        gap: 2rem;
    }

    .multi_payment_card {
        flex-direction: column;
        gap: 2rem;
    }

    .credit_card_section {
        margin-top: 2rem;
    }

    .choose_method_section {
        margin-top: 3rem;
        padding: 2rem;
    }

    .three_card_withdrawal {
        .three_card_section {
            flex-direction: column;
        }
    }

    .icon_balance_heading {
        gap: 1rem;

        .balance {
            font-size: 2.4rem;
        }

        svg {
            font-size: 5rem;
        }
    }

    .wallet_page_section {
        h2 {
            font-size: 1.8rem;
            padding-bottom: 0rem;
        }
    }
}

@media only screen and (max-width: 575px) {
    .card_wallet {
        align-items: self-start;
        flex-direction: column;
        gap: 1rem;
    }

    .add_amount_btn {
        justify-content: end;
        gap: 1rem;
        width: 100%;
    }
}
