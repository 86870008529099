.Side_bar_section {
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100vh;
    background-color: #111727;
    display: flex;
    flex-direction: column;
    transition: 0.5s all ease-in-out;
    z-index: 999;
}

.logo_side_bar {
    padding: 3rem;
    text-align: center;
    img {
        max-width: 15rem;
        margin: 0 auto;
        filter: brightness(0) invert(1);
        transition: 0.5s all ease-in-out;
    }

    .side_bar_close_logo {
        max-width: 5rem;
        transition: 0.5s all ease-in-out;
    }
}

.menu_card_sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 1rem;
    .menu_item,
    .accordion-button {
        text-decoration: none;
        padding: 1rem 2rem;
        display: inline-flex;
        align-items: center;
        column-gap: 1.5rem;
        font-size: 1.6rem;
        color: #fff;
        font-weight: 400;
        margin-bottom: 1rem;
        position: relative;
        transition: 0.3s all ease-in-out;
        width: 100%;

        svg {
            width: 2.2rem;
            height: 2.2rem;
        }

        .count {
            background: #ffffff;
            font-size: 12px;
            width: 22px;
            height: 22px;
            border-radius: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #1414dd;
            font-weight: bold;
        }


        &.active,
        &:hover {
            color: #1414dd;
            background: #fafbff;
            &::before {
                height: 100%;
                width: 5px;
                background-color: #1414dd;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
            }

            .count {
                background: #1414dd;
                color: #fff;
            }
        }

        span.inner-flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }
}

.side_nav_open {
    .Side_bar_section {
        width: 100px;
        transition: 0.5s all ease-in-out;
    }

    .side_bar_open_logo {
        display: none;
        transition: 0.5s all ease-in-out;
    }

    .side_bar_close_logo {
        display: block;
        transition: 0.5s all ease-in-out;
    }

    .logo_side_bar {
        padding: 3rem 2rem;
        transition: 0.5s all ease-in-out;
    }

    .menu_card_sidebar {
        .menu_item {
            justify-content: center;
            span {
                display: none;
            }
        }
    }

    .sub_menu_heading {
        span {
            display: none;
        }
    }

    .sub_menu_sidebar {
        .accordion-button {
            &::after {
                display: none;
            }
        }

        .accordion-button {
            justify-content: center;
        }
    }
}

.side_bar_close_logo {
    display: none;
    transition: 0.5s all ease-in-out;
}

.admin_sidebar_section {
    .menu_card_sidebar {
        .menu_item {
            margin-bottom: 1.5rem;
        }
    }
}

.sub_menu_sidebar {
    .accordion-item {
        background: transparent;
        border: none;

        &:first-of-type {
            .accordion-button {
                border-radius: 0;
                margin-bottom: 0;
                padding: 1.2rem 2rem;
            }
        }
    }

    .accordion-header {
        margin-bottom: 0.5rem;
    }

    .accordion-body {
        padding: 0;

        .menu_item {
            padding-left: 5.5rem;
        }
    }

    .accordion-button.collapsed {
        background-color: transparent;
        border-radius: 0;
        color: #fff;

        &.active,
        &:hover {
            color: #1414dd;
            background: #fafbff;
            border-radius: 0;
        }

        &::before {
            display: none;
        }

        &:hover {
            &::before {
                display: block;
            }
        }
    }

    .accordion-button {
        background-color: #fff;
        color: #1414dd;
        position: relative;

        &:focus {
            border-color: unset;
            box-shadow: unset;
        }

        &::before {
            height: 100%;
            width: 5px;
            background-color: #1414dd;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
        }

        &::after {
            background-size: 18px;
            width: 18px;
            height: 18px;
        }
    }
}

.close_sidebar_cross {
    display: none;
}

@media only screen and (max-width: 1199px) {
    .Side_bar_section {
        left: -300px;

        .side_bar_open_logo {
            display: block;
        }

        .side_bar_close_logo {
            display: none;
        }
    }

    .side_nav_open {
        position: relative;
        overflow: hidden;

        .Side_bar_section {
            width: 300px;
            left: 0;
        }
        .menu_card_sidebar {
            .menu_item {
                justify-content: flex-start;
                span {
                    display: block;
                }
            }
        }

        &::after {
            content: "";
            background-color: #11172761;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 99;
            position: fixed;
            overflow-x: hidden;
            overflow-y: auto;
            transition: 0.5s all ease-in-out;
        }

        .logo_side_bar {
            display: none;
        }
    }

    .menu_card_sidebar {
        margin-top: 5rem;
    }

    .close_sidebar_cross {
        position: absolute;
        background: transparent;
        padding: 0;
        border: none;
        color: #fff;
        font-size: 3rem;
        right: 1rem;
        top: 1rem;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
