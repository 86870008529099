.main_header_top {
    background-color: #ffffff;
    width: calc(100% - 300px);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
    transition: 0.5s all ease-in-out;
    box-shadow: 0px 0px 8px #00000016;
}

.top-header-sidebar {
    height: 8rem;
    display: flex;
    align-items: center;
    margin: 0 3rem;
    gap: 3rem;
}

.toggle_btn_button {
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: none;
    border-radius: 0.4rem;
    padding: 0 0.6rem;
    row-gap: 7px;
    background-color: transparent;
    .line_btn {
        width: 18px;
        height: 3px;
        background-color: #111727;
        border-radius: 1rem;
        transition: 0.3s all ease-in-out;
        &:last-child,
        &:first-child {
            width: 27px;
            transition: 0.3s all ease-in-out;
        }
    }
}

.side_nav_open {
    .toggle_btn_button {
        row-gap: 0;
        .line_btn {
            width: 0;
            &:last-child,
            &:first-child {
                width: 25px;
                position: absolute;
                transition: 0.3s all ease-in-out;
            }
            &:last-child {
                transform: rotate(45deg);
            }
            &:first-child {
                transform: rotate(-45deg);
            }
        }
    }
}

.profile {
    .dropdown,
    .dropdown-toggle {
        width: 5rem;
        min-width: 5rem;
        height: 5rem;
        border-radius: 50%;
    }
    .dropdown-toggle {
        border: none;
        padding: 0;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        &::after {
            content: unset;
        }

        &:focus-visible {
            background-color: transparent;
            box-shadow: none;
        }
    }
    &.drop_card_common {
        .dropdown-menu {
            width: 18rem;
        }
    }
}

.search_input_profile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    .headerSearchBx {
        min-width: 50rem;
        position: relative;
        .search_header_icon {
            position: absolute;
            top: 50%;
            left: 2rem;
            transform: translateY(-50%);
            svg {
                width: 2rem;
                height: 2rem;
            }
        }
        .common_input_filed {
            padding: 0 2rem 0 6rem;
            height: 4rem;
        }

        .globalSearch-suggestion-wrap {
            position: absolute;
            background: #fff;
            left: 0;
            right: 0;
            max-height: 300px;
            border-radius: 0 0 10px 10px;
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.16);
            overflow-x: scroll;
            ul {
                margin: 0;
                padding: 0;

                li {
                    padding: 14px;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    &:hover{
                        background-color: #3f51b5;
                        color: #fff;

                        .search_title_icon {
                            svg{
                                path {
                                    stroke: #fff;
                                    fill: #fff;
                                }
                            }
                        }
                    }

                    &:not(:first-child) {
                        border-top: 0.2px solid #f7f7f7;
                    }

                    .search_title_icon {
                        display: flex;
                        // align-items: center;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        gap: 10px;

                        span {
                            font-size: 16px;
                            text-transform: capitalize;
                            display: block;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: calc(100% - 32px);
                            overflow: hidden;
                        }

                        .share_next_page {
                            width: auto;
                            min-width: auto;
                            height: auto;
                            padding: 0;
                            background-color: transparent;
                        }

                        svg {
                            width: 1.6rem;
                            height: 1.6rem;
                        }
                    }

                    p {
                        font-size: 14px;
                        word-wrap: break-word;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.side_nav_open {
    .main_header_top {
        transition: 0.5s all ease-in-out;
        width: calc(100% - 100px);
    }
}

.top_header_logo {
    display: none;
    img {
        width: 4.5rem;
    }
}

.notification_profile_button {
    display: flex;
    align-items: center;
    column-gap: 2rem;

    .notifications_btn {
        border: none;
        padding: 0;
        font-size: 3rem;
        background-color: transparent;
        color: #111727;
        position: relative;

        .notification_count {
            position: absolute;
            font-size: 10px;
            top: 2px;
            right: -6px;
            color: #fff;
            z-index: 9;
            font-weight: 400;
            background: #1414dd;
            border-radius: 50%;
            width: 23px;
            height: 23px;
            min-width: 23px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .main_header_top {
        width: 100%;
    }
    .toggle_btn_button {
        .line_btn {
            &:first-child,
            &:last-child {
                width: 25px;
            }
        }
    }
    .side_nav_open {
        .main_header_top {
            width: 100%;
            z-index: 99;
        }
    }
    .top_header_logo {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .search_input_profile {
        .headerSearchBx {
            min-width: auto;
            width: 100%;

            .common_input_filed {
                padding: 0 1rem 0 3rem;
            }
            .search_header_icon {
                left: 0.5rem;
            }
        }
    }

    .top-header-sidebar {
        margin: 0 1.5rem;
        gap: 1rem;
    }

    .profile {
        .dropdown,
        .dropdown-toggle {
            width: 4.5rem;
            min-width: 4.5rem;
            height: 4.5rem;
        }
    }
}

@media only screen and (max-width: 575px) {
    .profile {
        .dropdown-toggle,
        .dropdown {
            width: 4rem;
            min-width: 4rem;
            height: 4rem;
        }
    }

    .notification_profile_button {
        column-gap: 1rem;
    }

    .notifications_btn {
        font-size: 2.5rem;
    }

    .search_input_profile {
        gap: 1rem;
    }
}
