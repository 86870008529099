
.main_content_section {
    margin-top: 8rem;
    padding: 5rem 4rem 11rem 4rem;
    min-height: calc(100vh - 8rem);
    width: calc(100% - 300px);
    margin-left: auto;
    background-color: rgb(240 241 247);
    transition: 0.5s all ease-in-out;
}

.side_nav_open {
    .main_content_section {
        transition: 0.5s all ease-in-out;
        width: calc(100% - 100px);
    }
}

.super_admin_main_section{
    .main_content_section {
        padding: 5rem 4rem;
    }
}


@media only screen and (max-width: 1199px) {
    .main_content_section {
        width: 100%;
        padding: 5rem 3rem 10rem;
    }
    .side_nav_open {
       .main_content_section {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 767px){
.main_content_section {
    padding: 3rem 1.5rem 12rem;
}

.super_admin_main_section .main_content_section {
    padding: 3rem 1.5rem;
}
}