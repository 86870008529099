// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

@font-face {
    font-family: "Rubik";
    src:
        url("../public/fonts/Rubik-ExtraBold.woff2") format("woff2"),
        url("../public/fonts/Rubik-ExtraBold.woff") format("woff");
    // url('Rubik-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    src:
        url("../public/fonts/Rubik-Bold.woff2") format("woff2"),
        url("../public/fonts/Rubik-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    src:
        url("../public/fonts/Rubik-Black.woff2") format("woff2"),
        url("../public/fonts/Rubik-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    src:
        url("../public/fonts/Rubik-Regular.woff2") format("woff2"),
        url("../public/fonts/Rubik-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    src:
        url("../public/fonts/Rubik-Light.woff2") format("woff2"),
        url("../public/fonts/Rubik-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    src:
        url("../public/fonts/Rubik-Medium.woff2") format("woff2"),
        url("../public/fonts/Rubik-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    src:
        url("../public/fonts/Rubik-SemiBold.woff2") format("woff2"),
        url("../public/fonts/Rubik-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

html {
    font-size: 62.5%;
}

::-webkit-scrollbar,
::-webkit-scrollbar-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
    border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
    background-color: #d1d1d1;
    border-radius: 1rem;
}

body {
    font-size: 1.6rem;
    font-weight: 400;
    color: #111727;
    // font-family: 'Poppins', sans-serif;
    // font-family: 'Montserrat', sans-serif;
    font-family: "Rubik";
}

.container {
    width: 100%;
    max-width: 152rem;
    padding: 0 2rem;
}

.common_label {
    width: 100%;
    font-size: 1.6rem;
    font-weight: 400;
    color: #111727;
    margin-bottom: 1rem;
    white-space: nowrap;
}

.tags_line_table {
    span {
        background: rgba(219, 221, 233, 0.568627451);
        color: #000;
        padding: 2px 8px;
        border-radius: 5px;
        margin-right: 5px;
        display: inline-block;
        font-size: 12px;
    }
}

.common_input_filed {
    width: 100%;
    height: 4.5rem;
    border-radius: 0.4rem;
    border: 1px solid #d6d6d6;
    padding: 0 2rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: #111727;

    &:focus,
    &:focus-visible {
        border: 1px solid #111727;
        outline: unset;
    }

    &::placeholder {
        color: #111727;
    }
}

.text_area_common {
    width: 100%;
    border-radius: 0.4rem;
    border: 1px solid #d6d6d6;
    padding: 1rem 2rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: #111727;

    &:focus,
    &:focus-visible {
        border: 1px solid #111727;
        outline: unset;
    }

    &::placeholder {
        color: #111727;
    }
}

.type_input {
    .css-1nlw9hx {
        min-width: 100%;

        .MuiInputBase-root {
            border-radius: 0.4rem;
            font-size: 1.4rem;
            font-weight: 400;
            color: #111727;
            min-height: 4.5rem;
            padding: 0.6rem 2rem;
            align-items: center;
            gap: 0.5rem;

            input {
                font-size: 1.4rem;
                color: #111727;
            }
        }
    }

    button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
        padding: 0;
        font-size: 20px;

        svg{
            font-size: 20px;
            color: #10109b;
        }
    }

    .css-4kuu2d {
        margin: 0;
        background: #111727;
        color: #fff;
        font-size: 1.2rem;
        border-radius: 0.5rem;
        padding: 3px 0;
        height: auto;

        svg {
            color: #fff;
        }
    }

    .MuiFormControl-root.MuiTextField-root.MuiChipsInput-TextField.css-uqjlkp-MuiFormControl-root-MuiTextField-root {
        min-width: 100%;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root {
        padding: 0.6rem 3rem 0.6rem 2rem;
        min-height: 4.5rem;
        align-items: center;
        gap: 0.5rem;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 0.4rem;
        padding: 0 2rem;
        font-size: 1.4rem;
        font-weight: 400;
        color: #111727;
        min-height: 4.5rem;
    }

    .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiChipsInput-Chip.css-1odvtz4-MuiButtonBase-root-MuiChip-root {
        margin: 0;
        background: #111727;
        color: #fff;
        font-size: 1.2rem;
        border-radius: 0.5rem;
        padding: 3px 0;
        height: auto;

        svg {
            color: #fff;
        }
    }

    .css-uqjlkp-MuiFormControl-root-MuiTextField-root .MuiInputBase-root input {
        font-size: 1.4rem;
        color: #111727;
    }
}

.search_box_dropdown {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;

    .search_input_profile {
        .headerSearchBx {
            min-width: 36rem;
            position: relative;
        }
    }

    select.common_input_filed {
        background-position: center right 1rem;
        padding-right: 3.5rem;
        min-width: 20rem;
        background-color: #fff;
    }
}

.marketing_img_pdf {
    width: 270px;
    margin-bottom: 20px;
}

.btn_blue.copy_btn {
    height: 3rem;
    min-width: auto;
    background: #009688;
    border-color: #009688;
    font-size: 1.4rem;
    padding: 0 1rem;
}

.link-holder {
    .btn_blue.copy_btn {
        position: absolute;
        right: 0;
        top: 0;
        height: 4.5rem;
        min-width: 6rem;
    }
}

.text_copy_btn_section {
    position: relative;

    .link-holder {
        white-space: nowrap;
        line-height: 4.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 7rem;
    }

    .btn_blue.copy_btn {
        height: 4.5rem;
        width: 6rem;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.price_icon {
    margin-right: 3px;
}

.card_description_text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

select.common_input_filed {
    appearance: none;
    background-image: url('data:image/svg+xml,<svg viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="%23000000" /></svg>');
    background-repeat: no-repeat;
    background-size: 1.6rem;
    background-position: center right 2rem;
    padding-right: 4.5rem;
    background-color: transparent;
}

.error {
    font-size: 1.5rem;
    color: red;
    margin-top: 0.5rem;
    font-weight: 400;
    // display: none;
}

.check_box_common {
    display: flex;
    align-items: center;
    gap: 1rem;

    input {
        width: 2rem;
        min-width: 2rem;
        height: 2rem;
        appearance: none;
        border: 1px solid #d6d6d6;
        border-radius: 0.3rem;
        background-color: transparent;

        &:checked {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g id="Group_43932" data-name="Group 43932" transform="translate(-1131 -421)"><g id="Rectangle_20009" data-name="Rectangle 20009" transform="translate(1131 421)" fill="%231414dd" stroke="%231414dd" stroke-width="1"><rect width="20" height="20" rx="3" stroke="none"/><rect x="0.5" y="0.5" width="19" height="19" rx="2.5" fill="none"/></g><path id="Icon_ionic-ios-checkmark" data-name="Icon ionic-ios-checkmark" d="M24.25,13.451l-1.135-1.167a.244.244,0,0,0-.181-.077h0a.234.234,0,0,0-.181.077L14.885,20.21l-2.864-2.864a.249.249,0,0,0-.361,0l-1.148,1.148a.257.257,0,0,0,0,.368l3.612,3.612a1.142,1.142,0,0,0,.755.368,1.2,1.2,0,0,0,.748-.355h.006l8.623-8.668A.276.276,0,0,0,24.25,13.451Z" transform="translate(1123.564 413.794)" fill="%23fff"/></g></svg>');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 2rem;
            border-color: #1414dd;
        }
    }

    label {
        font-size: 1.4rem;
        font-weight: 400;
        color: #111727;
    }
}

.btn {
    height: 5rem;
    background: #111727;
    font-size: 1.8rem;
    color: #fff;
    border: 1px solid #111727;
    border-radius: 0.4rem;
    padding: 0 2rem;
    min-width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    transition: 0.3s all ease-in-out;

    &:hover {
        background-color: transparent;
        border-color: #111727;
        color: #111727;
    }
}

.btn_blue {
    min-width: 16rem;
    padding: 0 2rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #111727;
    background: #111727;
    border-radius: 0.4rem;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 400;
    transition: 0.3s all ease-in-out;
    fill: #fff;
    column-gap: 1rem;
    text-decoration: none;

    &:hover {
        color: #fff;
        background-color: #000;
        border-color: #000;
    }

    svg {
        width: 2rem;
        height: 2rem;
    }
}

.main_heading_top {
    font-size: 3rem;
    font-weight: 400;
    margin: 0;
    padding-bottom: 1rem;
    color: #111727;
    position: relative;

    &::after {
        content: "";
        width: 5rem;
        height: 5px;
        background-color: #1414dd;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 2px;
    }
}

.video_link_section_main {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px;

    .title_link_video {
        border-radius: 10px;
        background: #ffffff;
        color: #111727;

        iframe {
            border-radius: 10px 10px 0 0;
            background: #000;
        }
    }

    .video_link_text {
        color: #1414dd;
        font-size: 16px;
    }
}

.content_video {
    padding: 10px 20px;

    h5 {
        font-size: 20px;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 5px;
    }

    p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 400;
    }
}

.drop_card_common {
    .dropdown-menu {
        padding: 5px 0;
        border: 1px solid #d6d6d6;
        border-radius: 0.5rem;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.16);
        width: 14rem;
        overflow: hidden;

        .nav-link {
            font-size: 1.6rem;
            padding: 0.6rem 2rem;
            margin-bottom: 0.5rem;
            font-weight: 400;

            &:last-child {
                margin-bottom: 0;
            }

            button {
                padding: 0;
                border: none;
                background: transparent;
                width: 100%;
                text-align: start;
            }

            &:hover,
            &:active {
                color: #fff;
                background-color: #111727;

                button {
                    color: #fff;
                }
            }
        }
    }
}

.common_input_filed_file {
    ::file-selector-button {
        width: 20rem;
        height: 4.5rem;
        background-color: transparent;
        border: 1px dashed #d6d6d6;
        padding: 0;
        margin-right: 1.3rem;
    }
}

.common_input_filed_file_common {
    ::file-selector-button {
        height: 4.5rem;
        border-radius: 0.4rem;
        border: 1px solid #d6d6d6;
        padding: 0 2rem;
        font-size: 1.4rem;
        font-weight: 400;
        color: #111727;
        background: transparent;
    }
}

.description_text {
    margin: 1rem auto 0;
    font-size: 16px;
    max-width: 38rem;
}

.delete_cion_box {
    width: 10rem;
    height: 10rem;
    background: rgba(241, 94, 94, 0.18);
    border-radius: 50%;
    margin: 0 auto 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        font-size: 50px;
        color: #e93a3a;
    }
}

.Toastify__toast {
    gap: 15px;
}

.tow_btn_row {
    .btn_blue {
        background-color: #dfe3e8;
        border-color: #dfe3e8;
        color: #161616;

        &:hover {
            background-color: #dfe3e8;
            border-color: #dfe3e8;
            color: #161616;
        }

        + .btn_blue {
            background: #1414dd;
            color: #fff;
            border-color: #1414dd;

            &:hover {
                background: #10109b;
                border-color: #10109b;
                color: #fff;
            }

            &.delete_btn {
                background-color: #e93a3a;
                border-color: #e93a3a;
            }
        }
    }
}

.modal-content {
    border-radius: 1rem;
}

.common_design_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.marketer_img .common_design_img {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
}

.common_btn_admin {
    border: none;
    padding: 0;
    min-width: 3rem;
    min-height: 3rem;
    border-radius: 50%;
    font-size: 1.8rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    &:hover {
        color: #fff;
    }

    &:first-child {
        margin-right: 1rem;
    }
}

.eye_btn_admin {
    background: #535151;
}

.edit_btn_admin {
    background-color: #3f51b5;

    &.delete_btn_admin {
        background: #ed5e68;
    }
}

.admin_main_content_section {
    margin-top: 5rem;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 3rem;
    border-radius: 1rem;
}

.cancel_submit_btn_admin {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;

    .btn_blue + .btn_blue {
        background: #1414dd;
        color: #fff;
        border-color: #1414dd;

        &:hover {
            background: #10109b;
            border-color: #10109b;
            color: #fff;
        }
    }
}

.profile_save_btn {
    margin-top: 3rem;

    .btn_blue {
        margin: 0 auto;
    }
}

.delete_btn_admin {
    background: #ed5e68;
}

.data_not_found_img {
    text-align: center;
    padding: 3rem 0;

    img {
        max-width: 60rem;
        height: auto;
        margin: 0 auto;
    }
}

.amount_add_section {
    padding-top: 3rem;
}

.btn_clear_plan {
    background: #009688;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    padding: 0 15px;
    color: #fff;
    margin-bottom: 15px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1199px) {
    .main_heading_top {
        font-size: 2.6rem;
    }
}

@media only screen and (max-width: 991px) {
    .admin_main_content_section {
        margin-top: 4rem;
        padding: 2rem;
    }
}

@media only screen and (max-width: 767px) {
    .btn_blue {
        padding: 0 1rem;
        height: 4.5rem;
        min-width: 10rem;
    }

    .btn {
        height: 4.5rem;
        font-size: 1.6rem;
    }

    .main_heading_top {
        font-size: 2.4rem;
    }

    .profile_save_btn {
        margin-top: 2rem;
    }

    .common_input_filed_file ::file-selector-button {
        width: 15rem;
    }

    .admin_main_content_section {
        margin-top: 3rem;
    }

    .common_input_filed {
        height: 4rem;
    }

    .data_not_found_img img {
        max-width: 30rem;
        width: 100%;
    }

    .text_area_common {
        max-height: 15rem;
    }

    .video_link_section_main {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 20px;
    }

    .content_video {
        p {
            font-size: 14px;
        }
    }

    .search_box_dropdown {
        flex-direction: column;
        gap: 10px;
    }
}

/* Common css */
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    background: #a2a2a2 !important;
    border-color: transparent !important;
    color: #ffffff !important;
}

@media only screen and (max-width: 575px) {
    .btn_blue,
    .btn {
        font-size: 1.4rem;
        height: 4rem;
    }

    .search_box_dropdown {
        .search_input_profile {
            .headerSearchBx {
                min-width: 32rem;
            }
        }
    }
}


.five_upload_img {
    display: flex;
    gap: 15px;
    align-items: center;
    overflow: hidden;
    flex-wrap: wrap;
    margin-bottom: 20px;

    img.img-fluid {
        width: 50px;
        height: 50px;
        object-fit: contain;
        object-position: center;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.register_input {
    padding: 0 4.5rem 0 6.5rem;
}